@import "../../variables";

.breadcrumbs {
  margin: 0.5rem;

  &__list {
    margin: 0;
    display: flex;
    flex-direction: row;
  }

  &__item {
    padding: 0.25rem 0 0.25rem 0.25rem;
    list-style: none;

    &:not(:last-child)::after {
      content: "  >";
      color: $dark-gray;
    }

    &--active {
      color: $orange;
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
