@import "../../variables";

.header {
  display: flex;
  justify-content: space-between;
  color: $background-default;
  background-color: $orange;

  &__title,
  &__actions {
    margin: 0 1rem;
  }

  &__actions {
    display: flex;
    align-self: center;
  }

  &__action-right-hand-links {
    display: block;

    // Match AWS Sign Out button
    padding: 0.9rem;
    margin: 0 0.3rem;
    background-color: $orange;
    font-weight: bold;
    font-family: "Amazon Ember", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &__action-right-hand-link {
    color: $background-default;
    text-decoration: none;
  }
}
