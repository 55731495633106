@import "../../variables";

.message {
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  margin: 0.5rem;
  padding: 1rem;

  &--default {
    border-color: $dark-gray;
    background-color: $background-alternate-1;
  }

  &--success {
    border-color: $green;
    background-color: lighten($green, 50);
  }

  &--failure {
    border-color: $red;
    background-color: lighten($red, 40);
  }
}
