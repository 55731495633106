// Primary
$orange: #f7910b;
$blue: #165788;
$gray: #adafaf;
$dark-gray: #4d4f53;

// Secondary
$red: #ed2939;
$light-blue: #009fda;
$yellow: #fed100;
$green: #34b233;

// background
$background-default: #ffffff;
$background-alternate-1: #f5f5f5;
